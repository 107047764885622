<template>
  <div class="detail">
    <div class="content">
      <!-- 顶部 -->
      <Header  :borderShow="true" titleName="内容详情" />
      <van-loading color="#888888" v-if="data.loading" vertical style="margin-top: 20vh;">加载中...</van-loading>
      <div v-else>
        <div class="card" style="padding-top: .3rem;">
          <div class="title">{{ data.detail.title }}</div>
          <div class="time flex">
            <div class="mr50">发布：{{ data.detail.addTime }}</div>
            <div>浏览：{{data.detail.click}}</div>
          </div>
          <div class="details_text" v-html="data.detail.content"></div>

        </div>


        
      </div>






    </div>

  </div>
</template>

<script setup>

import { reactive, onMounted } from "vue";
import Api from "../../utils/api";
import { useRouter, useRoute } from 'vue-router'
import Header from "../../components/Header.vue";
import { jsToCallApp } from '../../common/js/callApp1.0.0'
const route = useRoute()
const router = useRouter()

let data = reactive({
  loading: true,
  id: 0,
  detail: {},
});

onMounted(() => {

  data.id = route.query.id
  Api.newsDetails(data.id ).then(res => {
    data.loading = false
    if (res.status == 200) {
      data.detail = res.data
      data.detail.content = filterUnsafeHtml(res.data.content)

      data.detail.addTime = getYMDHMS(res.data.addTime)
      const jsonString = {
        shareUrl: 'https://quanzong.cwmia.com/#/news/details?id=' + data.id,//分享链接
        shareCorver: data.detail.imgUrl,
        shareTitle: '中国职工保障互助会',
        shareDesc: data.detail.title,//分享描述
      }
      // console.log(jsonString)
      openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })

      // console.log(res.data)
    }
  })


});



function openAppFunction(type, jsonData) {
  const useTimeOut = setTimeout(() => {
    jsToCallApp(
      {
        type: type,
        ...jsonData,
      },
      (data) => {
        console.log(type, data)
      },
    )
    clearTimeout(useTimeOut);
  }, 300);
}
const getYMDHMS = (timestamp) => {
  timestamp = timestamp.replace(/-/g,'/')
  let time = new Date(timestamp)
  let year = time.getFullYear()
  let month = time.getMonth() + 1
  let date = time.getDate()
  // let hours = time.getHours()
  // let minute = time.getMinutes()
  // let second = time.getSeconds()

  // if (month < 10) { month = '0' + month }
  // if (date < 10) { date = '0' + date }
  // if (hours < 10) { hours = '0' + hours }
  // if (minute < 10) { minute = '0' + minute }
  // if (second < 10) { second = '0' + second }
  return year + '年' + month + '月' + date + '日 '
  //  + hours + ':' + minute + ':' + second
}

const filterUnsafeHtml = (str) => {
    let regex = new RegExp('<video', 'gi');
    str = str.replace(regex, `<video poster="${data.detail.imgUrl?data.detail.imgUrl:'https://qzapi.cwmia.com/upload/video1.png'}"`);

  
  var arrEntities = {
    'lt': '<',
    'gt': '>',
    'nbsp': ' ',
    'amp': '&',
    'quot': '"'
  };
  
  return str.replace(/&(lt|gt|nbsp|amp|quot);/ig,
    function (all, t) {
      return arrEntities[t];
    });
}
</script>

<style lang='less' scoped>
.detail {
  display: flex;
  flex-flow: column;
  height: 100%;
  .details_text{
    /deep/img{
      max-width: 100% !important;
      display: block;
    }
	.details_text p{
		font-size: 16px;
	}
  }
  .content {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    background-color: #fff;

    .apply_list_empty {
      text-align: center;
      padding-top: 15vh;

      img {
        width: 6.2933rem;
        height: 4.52rem;
      }

      .first_div {
        font-size: .4667rem;
        font-weight: 500;
        margin-top: .6667rem;
      }

      .last_div {
        font-size: .36rem;
        color: #ACACAC;
        margin-top: .2rem;

      }
    }

    .card {
      padding: .4rem .5067rem;
    }

    .footer_btn {
      height: 2.3333rem;
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      background-color: #fff;
      box-shadow: 0px -0.1333rem .08rem 1px rgba(0, 0, 0, 0.03);

      >div {
        width: 9.3867rem;
        height: 1.1333rem;
        background: #E61D18;
        border-radius: .1067rem;
        margin: .2667rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .3867rem;
        color: #fff;
      }
    }

    .details_text {
      font-size: .4667rem;
      line-height: .7rem;
      /deep/video{
        width: 100%;
        border-radius: .1333rem;
      }
    }

    .time {
      font-size: .3867rem;
      color: #ACACAC;
      margin-bottom: .6667rem;
text-align: center;
      .mr50 {
        margin-right: .6667rem;
      }

      img {
        width: .4133rem;
        margin-right: .2133rem;
        vertical-align: text-top;
      }
    }

    .title {
		text-align: center;
      font-size: .56rem;
      font-weight: 600;
      margin-bottom: .4667rem;
    }

    .top {
      height: 1.1333rem;
      width: 100%;

      font-size: .44rem;
      text-align: center;
      padding-top: .3333rem;
      box-sizing: border-box;
      position: relative;

      .return {
        width: 1.2rem;
        height: 1.1333rem;
        position: absolute;
        left: 0;
        top: 0;
        padding-left: .4rem;
        display: flex;
        align-items: center;

        img {
          width: .2667rem;
        }
      }
    }





  }
}
</style>